import { isAfter, format, addMinutes, isBefore, parse } from 'date-fns'

/**
 * Converts a time string "10:00" to a Date instance, using today's date as a reference
 * @param {string} time
 */
function parseTimeToDate(time) {
  return parse(time, 'HH:mm', new Date())
}

/**
 * Is the first time string (ex: "22:00") after the second one
 * @param {string} time
 * @param {string} compareToTime
 */
export function isTimeAfter(time, compareToTime) {
  return isAfter(parseTimeToDate(time), parseTimeToDate(compareToTime))
}

/**
 * Adds minutes to a time string ( ie 10:00, returns 10:15 )
 * @param {string} time
 * @param {number} amount
 * @returns {string}
 */
export function addMinutesToTime(time, amount) {
  const date = parseTimeToDate(time)

  return format(addMinutes(date, amount), 'HH:mm')
}

/**
 * Is the time parameter higher than min and lower than max
 * @param {string} time
 * @param {string} min
 * @param {string} max
 */
export function isTimeBetween(time, min, max) {
  const timeAsDate = parseTimeToDate(time)

  return (
    isAfter(timeAsDate, parseTimeToDate(min)) &&
    isBefore(timeAsDate, parseTimeToDate(max))
  )
}
