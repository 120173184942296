<template>
  <el-form ref="form" :model="openingHours">
    <el-table :data="openingHours" class="working-hours-table">
      <!-- Day -->
      <el-table-column
        v-slot="{ row }"
        width="170"
        :label="$t('pages.settings.reservations.opening_hours.headers.day')"
      >
        {{ dayNames[row.day_index] }}
      </el-table-column>

      <!-- Open from -->
      <el-table-column
        v-slot="{ row, $index }"
        :label="
          $t('pages.settings.reservations.opening_hours.headers.open_from')
        "
        min-width="150"
      >
        <time-select
          v-if="row.closed === false"
          :model-value="row.open_from"
          :placeholder="
            $t('pages.settings.reservations.opening_hours.headers.open_from')
          "
          v-bind="timeSelectAttrs"
          start="00:00"
          end="23:45"
          :clearable="false"
          @update:model-value="commitChanges($index, 'open_from', $event)"
          @change="validate()"
        />
      </el-table-column>

      <!-- Open to -->
      <el-table-column
        v-slot="{ row, $index }"
        :label="$t('pages.settings.reservations.opening_hours.headers.open_to')"
        min-width="170"
      >
        <el-form-item
          :prop="`${$index}.open_to`"
          :rules="rules.open_to"
          :show-message="false"
          class="mb-0"
        >
          <time-select
            v-if="row.closed === false"
            :placeholder="
              $t('pages.settings.reservations.opening_hours.headers.open_to')
            "
            :model-value="row.open_to"
            v-bind="timeSelectAttrs"
            :start="getNextTimeInterval(row.open_from)"
            end="23:45"
            :clearable="false"
            @update:model-value="commitChanges($index, 'open_to', $event)"
          />
        </el-form-item>
      </el-table-column>

      <!-- Break from -->
      <el-table-column
        v-slot="{ row, $index }"
        :label="
          $t('pages.settings.reservations.opening_hours.headers.break_from')
        "
        min-width="170"
      >
        <el-form-item
          v-if="row.closed === false"
          :prop="`${$index}.break_from`"
          :rules="rules.break_from"
          :show-message="false"
          class="mb-0"
        >
          <time-select
            v-bind="timeSelectAttrs"
            :start="getNextTimeInterval(row.open_from)"
            :end="row.open_to"
            :placeholder="
              $t('pages.settings.reservations.opening_hours.headers.break_from')
            "
            :model-value="row.break_from"
            @change="validate()"
            @update:model-value="onBreakFromUpdate($index, $event)"
          />
        </el-form-item>
      </el-table-column>

      <!-- Break to -->
      <el-table-column
        v-slot="{ row, $index }"
        :label="
          $t('pages.settings.reservations.opening_hours.headers.break_to')
        "
        min-width="170"
      >
        <el-form-item
          v-if="row.closed === false"
          :prop="`${$index}.break_to`"
          :rules="rules.break_to"
          :show-message="false"
          class="mb-0"
        >
          <time-select
            v-bind="timeSelectAttrs"
            :placeholder="
              $t('pages.settings.reservations.opening_hours.headers.break_to')
            "
            :start="
              row.break_from ? getNextTimeInterval(row.break_from) : undefined
            "
            :end="row.open_to"
            :model-value="row.break_to"
            :disabled="row.break_from === null"
            @update:model-value="onBreakToUpdate($index, $event)"
          />
        </el-form-item>
      </el-table-column>

      <!-- Closed -->
      <el-table-column
        v-slot="{ row, $index }"
        :label="$t('pages.settings.reservations.opening_hours.headers.closed')"
        min-width="150"
        align="center"
        header-align="left"
      >
        <el-checkbox
          :model-value="row.closed"
          @update:model-value="commitChanges($index, 'closed', $event)"
        />
      </el-table-column>
    </el-table>
  </el-form>
</template>

<script>
import { isTimeAfter, addMinutesToTime, isTimeBetween } from '../../utils.js'
import TimeSelect from '@/components/time-select/index.vue'
import set from 'just-safe-set'
import cloneDeep from 'clone-deep'

export default {
  name: 'OpeningHoursForm',
  components: { TimeSelect },
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data() {
    const MINUTES_INTERVAL = 15
    return {
      MINUTES_INTERVAL,
      rules: {
        open_to: [{ validator: this.validateOpenToField, trigger: 'change' }],
        break_to: [
          { validator: this.validateBreakEndField, trigger: 'change' },
          {
            validator: this.validateBreakIsInsideOpeningHours,
            trigger: 'change'
          }
        ],
        break_from: [
          {
            validator: this.validateBreakIsInsideOpeningHours,
            trigger: 'change'
          }
        ]
      },
      timeSelectAttrs: {
        size: 'small',
        step: `00:${MINUTES_INTERVAL}`
      },
      dayNames: [
        this.$t('common.days.monday'),
        this.$t('common.days.tuesday'),
        this.$t('common.days.wednesday'),
        this.$t('common.days.thursday'),
        this.$t('common.days.friday'),
        this.$t('common.days.saturday'),
        this.$t('common.days.sunday')
      ]
    }
  },

  computed: {
    openingHours() {
      return [...this.modelValue]
    }
  },

  methods: {
    onBreakFromUpdate(rowIndex, value) {
      this.commitChanges(rowIndex, 'break_from', value)

      if (value === null) {
        // If they cleared the input, also remove the break_to value
        this.commitChanges(rowIndex, 'break_to', null)
      }
    },
    onBreakToUpdate(rowIndex, value) {
      this.commitChanges(rowIndex, 'break_to', value)

      if (value === null) {
        // If they cleared the input, also remove the break_from value
        this.commitChanges(rowIndex, 'break_from', null)
      }
    },
    validateBreakIsInsideOpeningHours({ field }, value, callback) {
      // the rule is used inside a v-for, so it starts with the index
      // of the row ( ex: "0.open_from" )
      const rowIndex = field.split('.')[0]

      const breakStart = this.openingHours[rowIndex].break_from
      const breakEnd = this.openingHours[rowIndex].break_to

      // If any properties are null, we don't need to validate
      if (breakStart === null || breakEnd === null) {
        return callback()
      }

      const opensAtValue = this.openingHours[rowIndex].open_from
      const closesAtValue = this.openingHours[rowIndex].open_to

      const isBreakWithinOpeningHours = isTimeBetween(
        value,
        opensAtValue,
        closesAtValue
      )

      if (isBreakWithinOpeningHours) {
        return callback()
      }

      // Errors inside the table don't look great, so we use a toast notification
      // and the error message is hidden from the input, but it will remain colored red
      const error = new Error(
        this.$t(
          'pages.settings.reservations.opening_hours.errors.break_is_inside_opening_hours',
          {
            day: this.dayNames[rowIndex]
          }
        )
      )

      this.$message({
        type: 'error',
        message: error.message,
        // This shows the error message, we dont want more than one to display
        // at the same time
        grouping: true
      })

      callback(error)
    },

    /**
     * @public
     */
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },

    getNextTimeInterval(time) {
      return addMinutesToTime(time, this.MINUTES_INTERVAL)
    },

    /**
     * This form sometimes models Vuex state, so we cannot mutate the data interally
     */
    commitChanges(index, field, value) {
      const { openingHours } = this

      const itemToUpdate = cloneDeep(openingHours[index])

      set(itemToUpdate, field, value)

      openingHours[index] = itemToUpdate

      this.$emit('update:modelValue', openingHours)
    },

    /**
     * Validates that closesAt comes after opensAt
     */
    validateOpenToField({ field }, closesAtValue, callback) {
      // the rule is used inside a v-for, so it starts with the index
      // of the row ( ex: "0.open_from" )
      const rowIndex = field.split('.')[0]
      const opensAtValue = this.openingHours[rowIndex].open_from

      const closesAfterItOpens = isTimeAfter(closesAtValue, opensAtValue)

      if (closesAfterItOpens) {
        return callback()
      }

      // Errors inside the table don't look great, so we use a toast notification
      // and the error message is hidden from the input, but it will remain colored red
      const error = new Error(
        this.$t(
          'pages.settings.reservations.opening_hours.errors.opening_time_is_after_closing_time',
          {
            day: this.dayNames[rowIndex]
          }
        )
      )

      this.$message({
        type: 'error',
        message: error.message,
        // This shows the error message, we dont want more than one to display
        // at the same time
        grouping: true
      })

      callback(error)
    },

    validateBreakEndField({ field }, breakEnds, callback) {
      // the rule is used inside a v-for, so it starts with the index
      // of the row ( ex: "0.open_from" )
      const rowIndex = field.split('.')[0]
      const breakStarts = this.openingHours[rowIndex].break_from

      if (breakStarts === null || breakEnds === null) {
        return callback()
      }

      const closesAfterItOpens = isTimeAfter(breakEnds, breakStarts)

      if (closesAfterItOpens) {
        return callback()
      }

      // Errors inside the table don't look great, so we use a toast notification
      // and the error message is hidden from the input, but it will remain colored red
      const error = new Error(
        this.$t(
          'pages.settings.reservations.opening_hours.errors.break_start_is_after_break_end',
          {
            day: this.dayNames[rowIndex]
          }
        )
      )

      this.$message({
        type: 'error',
        message: error.message,
        // This shows the error message, we dont want more than one to display
        // at the same time
        grouping: true
      })

      callback(error)
    }
  }
}
</script>

<style scoped>
/* 
* When a day is marked as closed, the select inputs are hidden.
* This CSS helps normalize the heights of the cells before/after hide
*/
.working-hours-table:deep(tbody td) {
  height: 61px;
}
</style>
