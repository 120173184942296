<template>
  <el-time-select
    v-model="modelValueFormatted"
    :format="elementPlusTimeFormat"
  />
</template>
<script>
import { parse, format } from 'date-fns'
import { parseTimeAsDate, TIME_FORMAT } from './utils'

/**
 * ElTimeSelect component with pre-defined options for time formatting.
 * The component emits time in format `HH:mm`, but displays the options
 * in the client time config format
 */
export default {
  name: 'TimeSelect',
  props: {
    modelValue: {
      type: String,
      default: undefined
    }
  },
  emits: ['update:modelValue'],
  computed: {
    modelValueFormatted: {
      get() {
        const { modelValue } = this

        if (!modelValue) {
          return
        }

        // We always store the time in this format in the database
        // Here, we want to create a date from this time, and then we will
        // format it back to the customer config pattern
        const date = parseTimeAsDate(modelValue, new Date())

        return format(date, this.clientTimeFormat)
      },
      set(time) {
        if (!time) {
          return this.$emit('update:modelValue', null)
        }

        // <ElTimeSelect /> displays time in the client time format.
        // We need to parse it back and then re-format it appropriately
        // according to the default time format for storage
        const date = parse(time, this.clientTimeFormat, new Date())

        // Format it back to the default time format that we use for storage and
        // emit that to the parent
        const timeFormatted = format(date, TIME_FORMAT)

        this.$emit('update:modelValue', timeFormatted)
      }
    },
    elementPlusTimeFormat() {
      // ElementPlus uses dayjs for date formatting and the patterns differ
      // We need a mapping of time format from the config to dayjs formats
      // https://day.js.org/docs/en/display/format#list-of-all-available-formats
      // This component uses date-fns/format
      // https://date-fns.org/v2.30.0/docs/format
      // This mapping contains only the formats which differ
      const timeFormatToElementPlusFormat = {
        'h:mm aa': 'h:mm A'
      }

      return (
        timeFormatToElementPlusFormat[this.clientTimeFormat] ||
        this.clientTimeFormat
      )
    },
    clientTimeFormat() {
      return this.$store.getters['Config/getTimeFormat']
    }
  }
}
</script>
