import { parse } from 'date-fns'

export const TIME_FORMAT = 'HH:mm'

/**
 * Parses a time string from the database to a datetime object
 * This can be used if you want to create a DateTime object from the
 * time that is saved in the database
 * @param {string} time
 * @param {Date} referenceDate
 * @returns {Date}
 */
export function parseTimeAsDate(time, referenceDate = new Date()) {
  return parse(time, TIME_FORMAT, referenceDate)
}
